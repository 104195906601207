import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SVGContactBg1 from "../components/SVG/contact-bg-1";
import SVGContactBg2 from "../components/SVG/contact-bg-2";
import BeeBanner from "../components/bee-banner";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Contact-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;

	//  const html = `<form
	//     action="https://formspree.io/f/"
	//     method="POST">
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Name" type="text" name="name">
	//   </div>
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Email Address" type="email" name="_replyto">
	//   </div>
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Telephone Number" type="text" name="telephone">
	//   </div>
	//   <div class="form-group">
	//    <textarea class="form-control bg-light border-0 py-5" rows="3" name="message" placeholder="Message"></textarea>
	//   </div>
	//   // Send Button
	//   <div class="g-recaptcha my-3" data-sitekey=""></div>
	//   <button class="btn btn-outline-primary mt-3" id="contact-send-btn" type="submit">Send Message</button>
	//   </form>`
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>
				{/* <Helmet>
					<script
						src="https://www.google.com/recaptcha/api.js"
						async
						defer
					></script>
				</Helmet> */}

				<GatsbySeo
					title="Contact Us | Hive HR Solutions"
					description="Contact Page / placeholder"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/contact-us`,
						title: "Contact Us | Hive HR Solutions",
						description: "Contact Page / placeholder",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className="position-relative">
					<SVGContactBg1
						style={{ zIndex: -2 }}
						className="position-absolute notXl d-none top-50 translate-middle-y h-auto w-100 d-lg-block "
					/>
					<SVGContactBg2
						style={{ zIndex: -2 }}
						className="position-absolute d-none top-50 translate-middle-y h-auto w-100 d-lg-block "
					/>
					<Container>
						<Row
							className="py-lg-10 py-5 justify-content-center
					"
						>
							<Col>
								<div
									className="bg-white "
									style={{
										overflow: "hidden",
										boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
									}}
								>
									<Row style={{ overflow: "hidden" }}>
										<Col
											className="position-relative    pt-lg-5 py-4 px-5  pe-xl-0"
											lg={9}
										>
											<Row>
												<Col lg={6}>
													{/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
													<h1>Contact Us</h1>
													<hr className="hr-primary w-60 mb-5" />
													<ContactForm />
													<div
														style={{ width: "20px" }}
														className="h-100 d-none d-lg-block top-0 end-0 bg-primary position-absolute"
													></div>
												</Col>
												<Col className="ps-lg-4  mt-5 mt-lg-0" lg={6}>
													<h1 className="text-white d-none d-lg-block">
														Details
													</h1>
													<hr className="bg-white w-60 mb-5 d-none d-lg-block" />

													<p>
														Horsham{" "}
														<a
															className=" link-orange align-items-center pb-3"
															href="tel:+441403627630"
														>
															01403 627 630
														</a>
													</p>
													<p>
														<Link
															to="/locations/hr-brighton"
															className="text-decoration-none seo-link"
														>
															Brighton
														</Link>{" "}
														<a
															className=" link-orange align-items-center pb-3"
															href="tel:+441273044124"
														>
															01273 044 124
														</a>
													</p>
													<p>
														<Link
															to="/locations/hr-worthing"
															className="text-decoration-none seo-link"
														>
															Worthing
														</Link>{" "}
														<a
															className=" link-orange align-items-center pb-3"
															href="tel:+441903910168"
														>
															01903 910 168
														</a>
													</p>
													<p>
														<Link
															to="/locations/hr-worthing"
															className="text-decoration-none seo-link"
														>
															Guildford
														</Link>{" "}
														<a
															className=" link-orange align-items-center pb-3"
															href="tel:+441483917416"
														>
															01483 917416
														</a>
													</p>

													<a
														className=" link-orange pb-3 d-flex align-items-center"
														href="mailto:ask@hivehrsolutions.co.uk"
													>
														<MdEmail className="me-2 text-primary" />
														ask@hivehrsolutions.co.uk
													</a>
													<a
														className=" link-orange text-decoration-none d-flex align-items-center"
														target="_blank"
														rel="noreferrer"
														href="https://goo.gl/maps/vWiSVzM5XswBGyC18"
													>
														Roffey Park Institute, Forest Rd,
														<br /> Horsham, RH12 4TB
													</a>
												</Col>
											</Row>
										</Col>
										<Col className="px-0 me-0" lg={3}>
											{" "}
											<GatsbyImage
												className="h-100 d-none d-lg-block"
												image={heroImg}
												alt={data.heroImg?.altText}
											></GatsbyImage>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		);
	}
};

//comment

export default ContactUsPage;
